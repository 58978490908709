import React from "react";
import "./Header.css";
import { Link, Navigate } from "react-router-dom";


const Header = ({ title }) => {

  // const reload = window.location.reload();

  return (
    <header className="header">
      <h5 className="title">{title}</h5>
      <div className="navbar-container">
        {process.env.REACT_APP_USE_EXPRESS_API === "true" ? (
          <Link to={"/"} className="home-container">
            Home
          </Link>
        ) : null}
        {process.env.REACT_APP_USE_EXPRESS_API === "true" ? (
          <Link to={"/create"} className="create-container">
            Create
          </Link>
        ) : null}
        {process.env.REACT_APP_USE_EXPRESS_API === "true" ? (
          <Link to={"/delete"} className="delete-container">
            Delete
          </Link>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
